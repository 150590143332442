import React from 'react';
import {Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import {withLocaleProvider} from '../locale/LocaleProvider';

export const NotFoundPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title="404"
        description={t('Home:seo:description')}
        url={t('Home:seo:url')}
      />
      <Layout>
        <h1>{t('404:title')}</h1>
        <Link to="/">{t('404:text')}</Link>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', NotFoundPage);
